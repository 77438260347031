import * as React from "react";
import {
    useColorMode,
    useColorModeValue,
    IconButton,
    IconButtonProps,
    Center,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;

const ColorModeSwitcherMobile: React.FC<ColorModeSwitcherProps> = (props) => {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    const hoverBgColor = useColorModeValue("primary.500", "primary.600");
    const hoverTextColor = useColorModeValue("primary.100", "primary.200");

    const MoonIcon = (
        <Center h="100%" w="100%">
            <FaMoon />
        </Center>
    );

    const SunIcon = (
        <Center h="100%" w="100%">
            <FaSun />
        </Center>
    );
    const SwitchIcon = useColorModeValue(MoonIcon, SunIcon);

    return (
        <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            margin="2"
            icon={SwitchIcon}
            onClick={toggleColorMode}
            aria-label={`Switch to ${text} mode`}
            _hover={{
                textDecoration: "none",
                color: hoverTextColor,
                bg: hoverBgColor,
            }}
            {...props}
        />
    );
};

export default ColorModeSwitcherMobile;
