import React from "react";
import { Link, Text, Center, HStack } from "@chakra-ui/layout";
import { useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { FaBook, FaGithub, FaGlobe, FaNpm } from "react-icons/fa";
import { LinkDetailClass } from "../../data/projects";

interface Props {
    linkDetail: LinkDetailClass;
}

const IconButton = (props: Props) => {
    const hoverTextColor = "primary.200";
    const hoverBgColor = "primary.700";

    const logoSize = useBreakpointValue({ base: 24, lg: 32 });

    const Logo = () => {
        switch (props.linkDetail.site) {
            case "GitHub":
                return <FaGithub size={logoSize} />;
            case "Demo":
            case "Live":
                return <FaGlobe size={logoSize} />;
            case "Study":
                return <FaBook size={logoSize} />;
            case "Npm":
                return <FaNpm size={logoSize} />;
        }
    };

    return (
        <Link
            href={props.linkDetail.link}
            border="2px solid"
            borderRadius="100px"
            py={1}
            px={2}
            textAlign="center"
            _hover={{
                textDecoration: "none",
                color: useColorModeValue(hoverTextColor, hoverBgColor),
                bg: useColorModeValue(hoverBgColor, hoverTextColor),
                borderColor: useColorModeValue(hoverBgColor, hoverTextColor),
            }}
            _focus={{
                ring: "2px",
                ringColor: "primary.500",
            }}
            isExternal
        >
            <Center>
                <HStack align="center" spacing={4}>
                    <Center>
                        <Logo />
                    </Center>
                    <Center mx={2} pt={1}>
                        <Text
                            fontSize={{ base: 18, md: 20, lg: 24 }}
                            fontWeight="bold"
                        >
                            {props.linkDetail.site}
                        </Text>
                    </Center>
                </HStack>
            </Center>
        </Link>
    );
};

export default IconButton;
