import React from "react";
import { Box } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import AnimationZoom from "../AnimationZoom";

interface Props {}

const CreditSection = (props: Props) => {
    const fontSize = { base: 16, lg: 24 };
    return (
        <AnimationZoom>
            <Box py={{ base: 4, lg: 8 }} id="credit">
                <Heading textAlign={{ base: "center" }} fontSize={fontSize}>
                    Developed by Zedrex
                </Heading>
            </Box>
        </AnimationZoom>
    );
};

export default CreditSection;
