import React from "react";
import { Box, Heading, Stack } from "@chakra-ui/layout";
import { Tooltip, Link } from "@chakra-ui/react";
import SubsectionDivider from "../SubsectionDivider/";
import { ContactClass } from "../../data/contacts";

interface Props {
    contact: ContactClass;
}

const Contact = (props: Props) => {
    const { type, address, link } = props.contact;

    const padding = { base: 6, lg: 12 };
    const headerSize = { base: 16, md: 24, xl: 28 };
    const descriptionSize = { base: 18, md: 36, lg: 48, xl: 56, "2xl": 64 };

    return (
        <Box py={padding}>
            <Stack direction={{ base: "column", xl: "row" }} align="flex-start">
                <Box w="100%">
                    <Heading as="h3" fontWeight="light" fontSize={headerSize}>
                        {type}
                    </Heading>
                    <SubsectionDivider />
                    <Tooltip
                        label="Click to open link"
                        fontWeight="bold"
                        onClick={() => {
                            navigator.clipboard.writeText(address);
                        }}
                    >
                        <Link
                            href={link}
                            mt={{ base: 0, lg: 8 }}
                            width={{ base: "100%" }}
                            fontSize={descriptionSize}
                            fontWeight="bold"
                            letterSpacing={2}
                            cursor="pointer"
                            _hover={{
                                textDecoration: "underline",
                            }}
                            isExternal
                        >
                            {address}
                        </Link>
                    </Tooltip>
                </Box>
            </Stack>
        </Box>
    );
};

export default Contact;
