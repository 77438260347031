import React from "react";
import { Box } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";
import AnimationAppear from "../AnimationAppear";

interface Props {}

const SubsectionDivider = (props: Props) => {
    const color = useColorModeValue("#2D3748", "#E2E8F0");

    return (
        <AnimationAppear duration={2}>
            <Box mt={2} mb={6} w={{ base: "66%" }}>
                <hr
                    style={{
                        border: 0,
                        height: "2px",
                        background: `${color}`,
                    }}
                ></hr>
            </Box>{" "}
        </AnimationAppear>
    );
};

export default SubsectionDivider;
