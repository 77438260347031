export interface ContactClass {
    type: string;
    address: string;
    link: string;
}

export const contacts: ContactClass[] = [
    {
        type: "Email",
        address: "kabeer.adib.abrar@gmail.com",
        link: "mailto:kabeer.adib.abrar@gmail.com",
    },
    {
        type: "GitHub",
        address: "github.com/zedrex",
        link: "https://github.com/zedrex",
    },
    {
        type: "LinkedIn",
        address: "linkedin.com/in/kabeer-adib-abrar",
        link: "https://www.linkedin.com/in/kabeer-adib-abrar/",
    },
];
