import React from "react";
import { Box, SimpleGrid, GridItem, Center } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import SectionHeading from "../SectionHeading/";
import { toolsets } from "../../data/toolset";
import Toolset from "./Toolset";
import Paragraph from "./Paragraph";
import AnimationSlide from "../AnimationSlide";

interface Props {}

const AboutSection = (props: Props) => {
    const margin = { base: 6, "2xl": 12 };

    return (
        <Box py={{ base: 24 }} id="about">
            <SectionHeading heading="About Me" />
            <SimpleGrid columns={{ base: 1, "2xl": 2 }}>
                <GridItem>
                    <AnimationSlide direction="right" delay={0.5}>
                        <Box w={{ base: "100%" }} mt={margin}>
                            <Center>
                                <Image
                                    display={{ base: "flex", "2xl": "none" }}
                                    src={"./images/adib-abrar-kabeer.png"}
                                    w={{ base: "100%", xl: "80%" }}
                                    alt="Adib Abrar Kabeer"
                                />
                            </Center>
                        </Box>
                    </AnimationSlide>
                    <Box w={{ base: "100%" }} mt={margin}>
                        <AnimationSlide direction="right" delay={0.25}>
                            <Paragraph>
                                I am Adib Abrar Kabeer, a fullstack web
                                developer currently majoring in Computer Science
                                and Engineering as an undergraduate student.
                            </Paragraph>
                        </AnimationSlide>
                        <AnimationSlide direction="right" delay={0.5}>
                            {toolsets.map((toolset) => {
                                return (
                                    <Toolset
                                        key={toolset.title}
                                        toolset={toolset}
                                    />
                                );
                            })}
                        </AnimationSlide>
                    </Box>
                </GridItem>
                <GridItem>
                    <AnimationSlide direction="right" delay={0.5}>
                        <Box w={{ base: "100%" }} m={margin}>
                            <Center>
                                <Image
                                    display={{ base: "none", "2xl": "flex" }}
                                    src={"./images/adib-abrar-kabeer.png"}
                                    alt="Adib Abrar Kabeer"
                                />
                            </Center>
                        </Box>
                    </AnimationSlide>
                </GridItem>
            </SimpleGrid>
        </Box>
    );
};

export default AboutSection;
