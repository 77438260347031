import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql, useStaticQuery } from "gatsby";

const SEO = (props: any) => {
    const { description, keywords, title, image, url, author } = props;

    const { site } = useStaticQuery(detailsQuery);

    const metaDescription = description || site.siteMetadata.description;
    const metaTitle = title || site.siteMetadata.title;
    const metaImage = image || site.siteMetadata.image;

    return (
        <Helmet
            title={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: "keywords",
                    content: site.siteMetadata.keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(
                metaImage
                    ? [
                          {
                              property: "og:image",
                              content: image,
                          },
                          {
                              property: "og:image:width",
                              content: metaImage.width,
                          },
                          {
                              property: "og:image:height",
                              content: metaImage.height,
                          },
                          {
                              name: "twitter:card",
                              content: "summary_large_image",
                          },
                      ]
                    : [
                          {
                              name: "twitter:card",
                              content: "summary",
                          },
                      ]
            )}
        />
    );
};

const detailsQuery = graphql`
    query DeafultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                image
                url
                keywords
            }
        }
    }
`;

export default SEO;
