import "focus-visible/dist/focus-visible";

import React from "react";
import { Box } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar/";
import HomeSection from "../components/HomeSection";
import ProjectSection from "../components/ProjectSection";
import AboutSection from "../components/AboutSection";
import CreditSection from "../components/CreditSection/";
import SectionDivider from "../components/SectionDivider";
import ContactSection from "../components/ContactSection/";
import SEO from "../components/SEO";

interface Props {}

const index = (props: Props) => {
    return (
        <Box overflow="hidden">
            <SEO />
            <Sidebar>
                <Box mx={{ base: 12, lg: 16, xl: 24 }}>
                    <HomeSection />
                    <SectionDivider />
                    <ProjectSection />
                    <SectionDivider />
                    <AboutSection />
                    <SectionDivider />
                    <ContactSection />
                    <SectionDivider />
                    <CreditSection />
                </Box>
            </Sidebar>
        </Box>
    );
};

export default index;
