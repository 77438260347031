import React from "react";

import { Heading } from "@chakra-ui/react";
import { Box, Stack, Text } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/layout";
import ColorModeSwitcher from "../ColorModeSwitcher";
import SidebarNavLink from "./SidebarNavLink";
import AnimationSlide from "../AnimationSlide";

const SidebarContent = (props: any) => {
    const { drawerClose } = props;

    return (
        <AnimationSlide direction="left">
            <Stack
                textAlign="right"
                spacing="12"
                p={{ base: 2, lg: 8 }}
                alignContent="center"
                height={{ base: "100%" }}
            >
                <Box>
                    <Box
                        display={{ base: "none", lg: "block" }}
                        mt={24}
                        mb={24}
                    >
                        <Link
                            _hover={{
                                textDecoration: "none",
                            }}
                            href={"./"}
                        >
                            <Heading fontSize="6xl"> zedrex</Heading>
                        </Link>
                        <Text fontSize="2xl" fontWeight="light">
                            Adib Abrar Kabeer
                        </Text>
                    </Box>
                    <Stack alignItems="space-between">
                        <Stack spacing={2} mt={2} align="stretch">
                            <SidebarNavLink
                                key={"#home"}
                                drawerClose={drawerClose}
                                href={"#home"}
                            >
                                <Text fontSize="2xl" fontWeight="bold">
                                    Home
                                </Text>
                            </SidebarNavLink>
                            <SidebarNavLink
                                key={"#projects"}
                                drawerClose={drawerClose}
                                href={"#projects"}
                            >
                                <Text fontSize="2xl" fontWeight="bold">
                                    Projects
                                </Text>
                            </SidebarNavLink>
                            <SidebarNavLink
                                key={"#about"}
                                drawerClose={drawerClose}
                                href={"#about"}
                            >
                                <Text fontSize="2xl" fontWeight="bold">
                                    About Me
                                </Text>
                            </SidebarNavLink>
                            <SidebarNavLink
                                key={"#contact"}
                                drawerClose={drawerClose}
                                href={"#contact"}
                            >
                                <Text fontSize="2xl" fontWeight="bold">
                                    Contact Me
                                </Text>
                            </SidebarNavLink>
                        </Stack>
                        <Box display={{ base: "none", lg: "block" }}>
                            <ColorModeSwitcher />
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </AnimationSlide>
    );
};

export default SidebarContent;
