export interface ToolsetClass {
    title: string;
    tools: string[];
}

export const toolsets: ToolsetClass[] = [
    {
        title: "Languages (Proficiency)",
        tools: ["C", "C++", "TypeScript"],
    },
    {
        title: "Languages (Familiar)",
        tools: ["C#", "Rust", "Python", "Java"],
    },
    {
        title: "Web Frontend",
        tools: [
            "React",
            "Chakra UI",
            "Redux",
            "Recoil",
            "Material UI",
            "Framer Motion",
        ],
    },
    {
        title: "Web Backend",
        tools: ["Node.js", "Express", "NestJS", "Strapi"],
    },
    {
        title: "Database & ORM",
        tools: ["Postgres", "Prisma", "MongoDB", "Firebase"],
    },
    {
        title: "Mobile Frontend",
        tools: ["Flutter"],
    },
];
