import React from "react";
import { Box } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import AnimationSlide from "../AnimationSlide";

interface Props {}

const HomeSection = (props: Props) => {
    const fontSize = { base: 22, md: 36, lg: 48, xl: 64, "2xl": 84 };
    const lineHeight = { base: 4, lg: 8, xl: 12, "2xl": 16 };

    return (
        <Box pt={{ base: "35vh", lg: "25vh" }} id="home" h="80vh">
            <AnimationSlide direction="right" delay={0.1}>
                <Heading fontWeight="light" mb={lineHeight} fontSize={fontSize}>
                    Full stack
                    <Box as="span" fontWeight="bold">
                        {" "}
                        web developer,
                    </Box>
                </Heading>
            </AnimationSlide>{" "}
            <AnimationSlide direction="right" delay={0.3}>
                <Heading fontWeight="light" mb={lineHeight} fontSize={fontSize}>
                    open source enthusiast,
                </Heading>{" "}
            </AnimationSlide>
            <AnimationSlide direction="right" delay={0.5}>
                {" "}
                <Heading fontWeight="light" mb={lineHeight} fontSize={fontSize}>
                    and{" "}
                    <Box as="span" fontWeight="bold">
                        software engineer
                    </Box>
                </Heading>
            </AnimationSlide>
        </Box>
    );
};

export default HomeSection;
