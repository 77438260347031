import * as React from "react";
import { useColorMode, useColorModeValue, Text, Box } from "@chakra-ui/react";
import { HStack } from "@chakra-ui/layout";
import { FaMoon, FaSun } from "react-icons/fa";

const ColorModeSwitcher = (props) => {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    const label = useColorModeValue("Dark", "Light");
    const hoverBgColor = useColorModeValue("primary.500", "primary.600");
    const hoverTextColor = useColorModeValue("primary.100", "primary.200");

    const MoonIcon = <FaMoon />;
    const SunIcon = <FaSun />;

    const SwitchIcon = () => {
        return useColorModeValue(MoonIcon, SunIcon);
    };

    const changeColorMode: any = () => {
        toggleColorMode();
    };

    return (
        <Box
            as="button"
            minW="15vw"
            px={3}
            py={2}
            mt={24}
            rounded={"md"}
            fontWeight="bold"
            _hover={{
                textDecoration: "none",
                color: hoverTextColor,
                bg: hoverBgColor,
            }}
            variant="ghost"
            color="current"
            onClick={changeColorMode}
            aria-label={`Switch to ${text} mode`}
            textAlign="right"
            {...props}
        >
            <HStack align="center" justifyContent="flex-end">
                <Box mx={4}>
                    <SwitchIcon />
                </Box>
                <Text
                    fontSize="2xl"
                    fontWeight="regular"
                    textAlign="right"
                    pt={1}
                >
                    {label} Mode
                </Text>
            </HStack>
        </Box>
    );
};

export default ColorModeSwitcher;
