import React from "react";
import { Box, Heading, Stack, HStack, SimpleGrid } from "@chakra-ui/layout";
import { Text, Image } from "@chakra-ui/react";
import { ProjectClass } from "../../data/projects";
import SubsectionDivider from "../SubsectionDivider";
import AnimationSlide from "../AnimationSlide";
import IconButton from "./IconButton";
import Tag from "./Tag";

interface Props {
    project: ProjectClass;
}

const Project = (props: Props) => {
    const {
        title,
        description,
        image,
        tags,
        linkDetails: links,
    } = props.project;

    const margin = { base: 24, lg: 48 };
    const headerSize = { base: 32, md: 40, lg: 48, xl: 64 };
    const descriptionSize = { base: 20, md: 24, xl: 28 };

    return (
        <AnimationSlide direction="right">
            <Box my={margin}>
                <SimpleGrid
                    columns={{ base: 1, xl: 2 }}
                    gap={{ base: 8, xl: 16 }}
                >
                    <Stack>
                        <Heading
                            as="h3"
                            fontWeight="light"
                            fontSize={headerSize}
                        >
                            {title}
                        </Heading>
                        <SubsectionDivider />
                        <Box>
                            <HStack
                                wrap="wrap"
                                justifyContent="flex-start"
                                spacing={2}
                            >
                                {tags.map((tag) => {
                                    return <Tag key={tag} text={tag} />;
                                })}
                            </HStack>
                        </Box>

                        <Image
                            py={8}
                            display={{ base: "flex", xl: "none" }}
                            w={{ base: "90%", md: "80%" }}
                            alignSelf="center"
                            cursor="pointer"
                            src={image}
                            alt={title}
                        />
                        <Text
                            pt={{ base: 0, lg: 8 }}
                            fontSize={descriptionSize}
                            fontWeight="normal"
                        >
                            {description}
                        </Text>
                        <SimpleGrid
                            columns={{ base: 2, md: 3, xl: 2, "2xl": 3 }}
                            gap={4}
                            pt={{ base: 8, lg: 12 }}
                        >
                            {links.map((link) => {
                                return (
                                    <IconButton
                                        key={link.site}
                                        linkDetail={link}
                                    />
                                );
                            })}
                        </SimpleGrid>
                    </Stack>
                    <Box>
                        <Image
                            display={{ base: "none", xl: "flex" }}
                            alignSelf="center"
                            cursor="pointer"
                            src={image}
                            alt={title}
                        />
                    </Box>
                </SimpleGrid>
            </Box>
        </AnimationSlide>
    );
};

export default Project;
