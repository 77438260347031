import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Heading, useColorModeValue } from "@chakra-ui/react";

interface Props {
    text: String;
}

const Tag: React.FC<Props> = (props: Props) => {
    const bgColor = useColorModeValue("primary.600", "primary.300");
    const textColor = useColorModeValue("primary.50", "primary.900");

    return (
        <Box
            sx={{
                margin: "4px !important",
            }}
            borderRadius={12}
            borderColor="primary.500"
            py={1}
            textAlign="center"
            w="fit-content"
            bgColor={bgColor}
            color={textColor}
        >
            <Heading
                as="p"
                fontWeight="bold"
                fontSize={{ base: "12", md: "md" }}
                whiteSpace="nowrap"
                textTransform="uppercase"
                letterSpacing={2}
                px={2}
                pt={1}
            >
                {props.text}
            </Heading>
        </Box>
    );
};

export default Tag;
