import React from "react";
import { Box } from "@chakra-ui/layout";
import Project from "./Project";
import { projects, ProjectClass } from "../../data/projects";
import SectionHeading from "../SectionHeading/SectionHeading";

interface Props {}

const ProjectSection = (props: Props) => {
    return (
        <Box py={{ base: 24 }} id="projects">
            <SectionHeading heading="Projects" />
            <Box>
                {projects.map((project: ProjectClass) => {
                    return <Project key={project.title} project={project} />;
                })}
            </Box>
        </Box>
    );
};

export default ProjectSection;
