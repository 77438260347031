import React from "react";
import { Box, Heading } from "@chakra-ui/layout";
import { SimpleGrid, Text } from "@chakra-ui/react";
import SubsectionDivider from "../SubsectionDivider/";
import { ToolsetClass } from "../../data/toolset";

interface Props {
    toolset: ToolsetClass;
}

const Toolset = (props: Props) => {
    return (
        <Box>
            <Heading
                mt={12}
                fontSize={{ base: "3xl", lg: "4xl", xl: "5xl" }}
                fontWeight="light"
            >
                {props.toolset.title}
            </Heading>
            <SubsectionDivider />
            <SimpleGrid columns={{ base: 2, md: 3, lg: 2, "2xl": 3 }} gap={4}>
                {props.toolset.tools.map((tool) => {
                    return (
                        <Box
                            key={tool}
                            borderRadius={12}
                            borderColor="primary.500"
                            border="2px solid"
                            py={2}
                            textAlign="center"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={{ base: "md", md: "lg", lg: "xl" }}
                                whiteSpace="nowrap"
                            >
                                {tool}
                            </Text>
                        </Box>
                    );
                })}
            </SimpleGrid>
        </Box>
    );
};

export default Toolset;
