import React from "react";
import { Text } from "@chakra-ui/react";

interface Props {}

const Paragraph = (props: { children: any }) => {
    return (
        <Text
            py={{ base: 8, xl: 16 }}
            fontSize={{ base: "xl", lg: "2xl", xl: "3xl" }}
            fontWeight="normal"
        >
            {props.children}
        </Text>
    );
};

export default Paragraph;
