import React from "react";
import {
    Box,
    Drawer,
    useBreakpointValue,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
} from "@chakra-ui/react";
import { Center, Flex, HStack, Stack } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";
import { FaBars, FaTimes } from "react-icons/fa";

import LogoContainer from "../LogoContainer";
import SidebarContent from "./SidebarContent";
import ColorModeSwitcherMobile from "../ColorModeSwitcherMobile";

const Sidebar = (props: any) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const hoverBgColor = useColorModeValue("primary.500", "primary.600");
    const hoverTextColor = useColorModeValue("primary.100", "primary.200");

    const CrossIcon = (
        <Center h="100%" w="100%">
            <FaTimes />
        </Center>
    );

    const BarIcon = (
        <Center h="100%" w="100%">
            <FaBars />
        </Center>
    );

    const isDrawerSidebar = useBreakpointValue({
        base: true,
        lg: false,
    });

    const DrawerMode = (
        <Box>
            <Box w="100%" h="100%">
                {props.children}
            </Box>

            <Drawer
                placement="right"
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={true}
            >
                <DrawerOverlay>
                    <DrawerContent
                        bgBlendMode={useColorModeValue("white", "gray.900")}
                    >
                        <IconButton
                            m={2}
                            w="10px"
                            size="md"
                            fontSize="lg"
                            variant="ghost"
                            color="current"
                            icon={CrossIcon}
                            aria-label={"Open Navigation Menu"}
                            display={{ base: "flex", lg: "none" }}
                            alignSelf="flex-end"
                            onClick={isOpen ? onClose : onOpen}
                            _hover={{
                                textDecoration: "none",
                                color: hoverTextColor,
                                bg: hoverBgColor,
                            }}
                        />
                        <DrawerBody>
                            <SidebarContent drawerClose={onClose} />
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </Box>
    );
    const SidebarMode = (
        <HStack align="stretch">
            <Box pos="fixed">
                <SidebarContent />
            </Box>
            <Box position="relative" left="20vw" h="100%" w="80vw">
                {props.children}
            </Box>
        </HStack>
    );

    return (
        <Stack>
            <Box pos="fixed" w="100vw">
                <Box
                    shadow="md"
                    bg={useColorModeValue("white", "gray.800")}
                    px={2}
                    display={{ lg: "none" }}
                >
                    <Flex
                        h={16}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <ColorModeSwitcherMobile display={{ lg: "none" }} />

                        <LogoContainer />

                        <IconButton
                            size="md"
                            fontSize="lg"
                            variant="ghost"
                            color="current"
                            icon={isOpen ? CrossIcon : BarIcon}
                            aria-label={"Open Navigation Menu"}
                            display={{ lg: "none" }}
                            onClick={isOpen ? onClose : onOpen}
                            _hover={{
                                textDecoration: "none",
                                color: hoverTextColor,
                                bg: hoverBgColor,
                            }}
                        />
                    </Flex>
                </Box>
            </Box>
            <Box>{isDrawerSidebar ? DrawerMode : SidebarMode}</Box>
        </Stack>
    );
};

export default Sidebar;
