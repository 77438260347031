import React from "react";
import { Heading } from "@chakra-ui/react";
import AnimationZoom from "../AnimationZoom/AnimationZoom";

interface Props {
    heading: string;
}

const SectionHeading = (props: Props) => {
    const fontSize = { base: 32, md: 48, xl: 96 };
    const { heading } = props;

    return (
        <AnimationZoom>
            <Heading
                as="h2"
                textAlign={{ base: "center", lg: "right" }}
                fontSize={fontSize}
            >
                {heading}
            </Heading>
        </AnimationZoom>
    );
};

export default SectionHeading;
