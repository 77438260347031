export interface ProjectClass {
    title: string;
    description: string;
    image: string;
    tags: string[];
    linkDetails: LinkDetailClass[];
}

export interface LinkDetailClass {
    site: string;
    link: string;
}

export const projects: ProjectClass[] = [
    {
        title: "IutForces",
        description: "IUT programming contest result tracker",
        image: "./images/iutforces-showcase.png",
        tags: ["react", "chakra ui"],
        linkDetails: [
            {
                site: "Live",
                link: "https://iutforces.netlify.app/",
            },
        ],
    },
    {
        title: "FridgeFoodies",
        description: "Web application for recipe creation, search and rating",
        image: "./images/fridgefoodies-showcase.png",
        tags: [
            "react",
            "recoil",
            "node.js",
            "express",
            "postgres",
            "chakra ui",
        ],
        linkDetails: [
            {
                site: "GitHub",
                link: "https://github.com/nandbyte/fridgefoodies",
            },
        ],
    },
    {
        title: "AutoResuma",
        description:
            "Web application to automatically create Resume with user data ",
        image: "./images/autoresuma-showcase.png",
        tags: [
            "react",
            "redux",
            "node.js",
            "express",
            "sequelize",
            "postgres",
            "chakra ui",
        ],
        linkDetails: [
            { site: "GitHub", link: "https://github.com/nandbyte/autoresuma" },
        ],
    },
    {
        title: "LifeLine",
        description:
            "Android app for electronic health record developed using Flutter and Firebase",
        image: "./images/lifeline-showcase.png",
        tags: ["flutter", "firebase", "android"],
        linkDetails: [
            { site: "GitHub", link: "https://github.com/nandbyte/lifeline" },
            {
                site: "Demo",
                link: "https://github.com/nandbyte/lifeline#showcase",
            },
        ],
    },
    {
        title: "AlgoSketch",
        description: `Algorithm visualizer created for Linux using C++ and SFML`,
        image: "./images/algosketch-showcase.png",
        tags: ["cpp", "sfml", "linux"],
        linkDetails: [
            {
                site: "GitHub",
                link: "https://github.com/zedrex/algosketch",
            },
            {
                site: "Demo",
                link: "https://github.com/zedrex/algosketch#algorithm-showcase",
            },
        ],
    },
    {
        title: "Cedit",
        description:
            "Fast and lightweight terminal-based text editor for Linux written in C",
        image: "./images/cedit-showcase.png",
        tags: ["c", "terminal", "linux"],
        linkDetails: [
            {
                site: "GitHub",
                link: "https://github.com/zedrex/cedit",
            },
            {
                site: "Demo",
                link: "https://github.com/zedrex/cedit#demo",
            },
        ],
    },
];
