import React from "react";
import { Box } from "@chakra-ui/layout";
import Contact from "./Contact";
import SectionHeading from "../SectionHeading/SectionHeading";
import AnimationSlide from "../AnimationSlide";
import { ContactClass, contacts } from "../../data/contacts";

const ContactSection = () => {
    return (
        <Box py={{ base: 24 }} id="contact">
            <SectionHeading heading="Contact Me" />
            <Box mt={{ base: 8, xl: 16 }}>
                <AnimationSlide direction="right" delay={0.25}>
                    {contacts.map((contact: ContactClass) => {
                        return <Contact key={contact.type} contact={contact} />;
                    })}
                </AnimationSlide>
            </Box>
        </Box>
    );
};

export default ContactSection;
